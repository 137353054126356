import './styles/index.css'
import './styles/App.css'
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Layout from "./pages/Layout";
import Velocity from "./pages/Velocity";
import Dash from "./pages/Dash";
import Capture from './pages/Capture';
import View from './pages/View';

export default function App() {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Velocity />} />
          <Route path="dash" element={<Dash />} />
          <Route path="capture" element={<Capture />} />
          {/* <Route path="view" element={<View />} />*/}
        </Route>
      </Routes>
    </HashRouter>
  );
}




const root = createRoot(document.getElementById("root"));
root.render(
<React.StrictMode>
  <Auth0Provider
    domain="dev-q1r5irv5iasiu1kg.us.auth0.com"
    clientId="am4PpFfcxkvyNwXA5mUL7X24r8B78tIs"
    authorizationParams={{
      redirect_uri: "http://localhost:3000/#/view"
    }}
>
    <App />
  </Auth0Provider>
</React.StrictMode>);