import '../../styles/CapturePanel.css';
import useWebSocket from "react-use-websocket";
import React, { useEffect, useState } from 'react';
import names from './NameList.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const zones = ['1', '6', '5']
const outcomes = ['ERR', '4', '3', '2', '1', 'ACE']
const rally_outcomes = ['W', 'L']
const types = ['SPIN', 'FLOAT', 'HYBRID']
const contexts = ['PRACTICE', 'MATCH']

const API_URL = process.env.REACT_APP_API_URL;
const WSS_FEED_URL = process.env.REACT_APP_WEBSOCKET_URL;
const IMAGE_URL = process.env.REACT_APP_CAPTURE_API_URL + '/image'
const FRAME_URL = process.env.REACT_APP_CAPTURE_API_URL + '/frame'

const CapturePanel = () => {

    const [serves, setServes] = useState([]);
    const [liveServes, setLiveServes] = useState([]);
    const [socketStatus, setSocketStatus] = useState("disconnected");

    const [serveContext, setServeContext] = useState("");
    const [servePlayer, setServePlayer] = useState("");
    const [serveType, setServeType] = useState("");
    const [serveVelocity, setServeVelocity] = useState("");
    const [serveTop, setServeTop] = useState("");
    const [serveBottom, setServeBottom] = useState("");
    const [serveUp, setServeUp] = useState(true);
    const [serveOutcome, setServeOutcome] = useState("");
    const [rallyOutcome, setRallyOutcome] = useState("");
    const [serveTime, setServeTime] = useState("");

    const [captureStatus, setCaptureStatus] = useState("");

    useWebSocket(WSS_FEED_URL, {
        onOpen: () => setSocketStatus('connected'),
        onClose: () => setSocketStatus('disconnected'),
        shouldReconnect: (closeEvent) => true,
        onMessage: (event) =>  processMessage(event)
    });

    // update liveServes every 2 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setLiveServes(processServes(serves));
        }, 2000);
        return () => clearInterval(interval);
    });
    useEffect(() => {
        setLiveServes(processServes(serves));
    }, [serves]);

    function getTimeDifference(timeString) {
        const currentTime = new Date();
        const givenTime = new Date(timeString);
        const timeDiff = Math.floor((currentTime - givenTime) / 1000);
        if (timeDiff < 0) {
            return 0;
        }
        else {
            return Math.min(timeDiff, 99);
        }
      }

    const processServes = (serves) => {
        return serves.map((serve) => {
            let serve_copy = {...serve};
            let ago = getTimeDifference(serve.timestamp);
            serve_copy.ago_timestamp = ago + ' sec ago';
            return serve_copy;
        });
    }

    const processMessage = (event) => {
        const msg = JSON.parse(event.data);
        
        if (msg.type === "STATUS") {
            setSocketStatus(`${msg.status} - delay ${msg.delay.toFixed(1)} sec`);
        }
        if (msg.type === "SERVE_FOUND") {
            let serve = {
                id: msg.request_id,
                timestamp: msg.request_day + ' ' + msg.timestamp
            }
            let serves_temp = [...serves, serve]
            serves_temp = serves_temp.slice(-3)
            setServes(serves_temp);
        } else if (msg.type === "SERVE_RESULT") {
            let serves_temp = [...serves]
            serves_temp.forEach((serve, i) => {
                if (serve.id === msg.request_id) {
                    serves_temp[i] = {
                        ...serve,
                        velocity: msg.velocity,
                        img_url: IMAGE_URL + '?day=' + msg.request_day + '&id=' + msg.request_id
                    }
                }
            });
            setServes(serves_temp);
        } else if (msg.type === "ERROR") {
            setSocketStatus('Error:' + msg.info);
        }
    };

    const elementFromServe = (serve, idx) => {
        if (serve.velocity) {
            return <div className="serve-velocity-clickable" key={'serve' + idx} onClick={() => {setServeVelocity(serve.velocity); setServeTime(serve.timestamp)}}>
                        <span className='serve-timestamp'>{serve.ago_timestamp}</span>
                        <br/>
                        <b>{serve.velocity} km/h</b>
                        <br/>
                        <img className="serve-image-small" alt="" src={serve.img_url}></img>
                    </div>
        } else {
            return <div className="serve-velocity" key={'serve' + idx}>
                        <span className='serve-timestamp'>{serve.ago_timestamp}</span>
                    </div>
        }
    }


    const clearData = (clearStatus) => {
        setServePlayer("");
        setServeType("");
        setServeVelocity("");
        setServeTop("");
        setServeBottom("");
        setServeOutcome("");
        setRallyOutcome("");
        setServeTime("");

        if (clearStatus) {
            setCaptureStatus("");
        }
    }

    const postServe = async () => {
        setCaptureStatus("...");

        if (servePlayer === "") {
            setCaptureStatus("Missing data: player");
            return;
        }

        if (serveContext === "") {
            setCaptureStatus("Missing data: practice/match");
            return;
        }

        if (serveType === "") {
            setCaptureStatus("Missing data: serve type");
            return;
        }

        if (serveTop === "" || serveBottom === "") {
            setCaptureStatus("Missing data: trajectory");
            return;
        }

        if (serveOutcome === "") {
            setCaptureStatus("Missing data: outcome");
            return;
        }

        if (serveVelocity === "") {
            setCaptureStatus("Missing data: velocity");
            return;
        }

        if (rallyOutcome === "") {
            setCaptureStatus("Missing data: rally outcome");
            return;
        }

        const response = await fetch(API_URL + "/capture", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                player: servePlayer,
                context: serveContext,
                type: serveType,
                velocity: serveVelocity,
                from: serveUp ? serveBottom : serveTop,
                to: serveUp ? serveTop : serveBottom,
                outcome: serveOutcome,
                rallyOutcome: rallyOutcome,
                serveTime: serveTime === '' ? new Date().toISOString() : new Date(serveTime).toISOString()
            })
        });
        if (response.status === 200) {
            setCaptureStatus("Serve posted");
            clearData(false);
        } else {
            const data = await response.json();
            setCaptureStatus("Error: " + data.error);
        }
    }

    return (
        <div className="App">
            <p>Socket status: {socketStatus}</p>
            <h1 className="Title">Serve Capture</h1>
            <div className='context-list'>
                {contexts.map((context, i) => {
                    return <div
                        key={'type' + i}
                        className={context===serveContext ? 'context-name selected' : 'context-name'}
                        onClick={() => setServeContext(context)}>
                            {context}
                        </div>
                })}
            </div>
            <div className='panes'>
                <div className='left-pane'>
                    <div className='player-list'>
                        {names.map((name, i) => {
                            return <div
                                    key={'player' + i} 
                                    className={name===servePlayer ? 'player-name-selected' : 'player-name'} 
                                    onClick={() => setServePlayer(name)}>
                                        {name}
                                    </div>
                        })}
                    </div>
                </div>
                <div className='right-pane'>
                    <div className='type-list'>
                        {types.map((type, i) => {
                            return <div
                                key={'type' + i}
                                className={type===serveType ? 'type-name selected' : 'type-name'}
                                onClick={() => setServeType(type)}>
                                    {type}
                                </div>
                        })}
                    </div>
                    <br/>
                    <div className='zones'>
                        <div className='zone-list'>
                            {zones.map((zone, i) => {
                                return <div
                                    key={'zone_top' + i}
                                    className={zone===serveTop ? 'zone-name selected' : 'zone-name'} 
                                    onClick={() => setServeTop(zone)}>
                                        {zone}
                                    </div>
                            })}
                        </div>
                        <div className='zone-arrow' onClick={() => setServeUp(!serveUp)}>
                            <FontAwesomeIcon icon={serveUp ? faArrowUp : faArrowDown}/>
                        </div>
                        <div className='zone-list-bottom'>
                            {zones.map((zone, i) => {
                                return <div 
                                    key={'zone_bottom' + i}
                                    className={zone===serveBottom ? 'zone-name selected' : 'zone-name'} 
                                    onClick={() => setServeBottom(zone)}>
                                        {zone}
                                    </div>
                            })}
                        </div>
                    </div>
                    <br/>
                    <span className='outcome-label'>Serve outcome:</span>
                    <div className='outcome-list'>
                        {outcomes.map((outcome, i) => {
                            return <div 
                                key={'outcome' + i}
                                className={outcome===serveOutcome ? 'outcome-name selected' : 'outcome-name'} 
                                onClick={() => setServeOutcome(outcome)}>
                                    {outcome}
                                </div>
                        })}
                    </div>
                    <br/>
                    <span className='outcome-label'>Rally outcome:</span>
                    <div className='rally-outcome-list'>
                        {rally_outcomes.map((outcome, i) => {
                            return <div 
                                key={'rally-outcome' + i}
                                className={outcome===rallyOutcome ? 'rally-outcome-name selected' : 'rally-outcome-name'} 
                                onClick={() => setRallyOutcome(outcome)}>
                                    {outcome}
                                </div>
                        })}
                    </div>
                </div>
            </div>
            <div className='velocity-input'>
                <input type='text' value={serveVelocity} onChange={(e) => setServeVelocity(e.target.value)}/>
            </div>
            <div className='capture-buttons'>
                <button id="capture-button" onClick={postServe}>Capture</button>
                <button onClick={() => clearData(true)}>Clear</button>
            </div>
            <div className='capture-status'>{captureStatus}</div>
            <div id='velocities'>
                {liveServes.map((serve,i) => elementFromServe(serve, i))}
            </div>
        </div>
    )

};

export default CapturePanel;