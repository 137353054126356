import '../../styles/ServePanel.css';
import useWebSocket from "react-use-websocket";
import React, { useState } from 'react';

const WSS_FEED_URL = process.env.REACT_APP_WEBSOCKET_URL;
const IMAGE_URL = process.env.REACT_APP_CAPTURE_API_URL + '/image'
const FRAME_URL = process.env.REACT_APP_CAPTURE_API_URL + '/frame'

const ServePanel = () => {

    const [serves, setServes] = useState([]);
    const [socketStatus, setSocketStatus] = useState("disconnected");
    
    useWebSocket(WSS_FEED_URL, {
        onOpen: () => setSocketStatus('connected'),
        onClose: () => setSocketStatus('disconnected'),
        shouldReconnect: (closeEvent) => true,
        onMessage: (event) =>  processMessage(event)
    });

    const processMessage = (event) => {
        const msg = JSON.parse(event.data);
        if (msg.type === "SERVE_FOUND") {
            let serve = {
                id: msg.request_id,
                timestamp: msg.timestamp
            }
            let serves_temp = [...serves, serve]
            serves_temp = serves_temp.slice(-3)
            setServes(serves_temp);
        } else if (msg.type === "SERVE_RESULT") {
            let serves_temp = [...serves]
            serves_temp.forEach((serve, i) => {
                if (serve.id === msg.request_id) {
                    serves_temp[i] = {
                        ...serve,
                        velocity: msg.velocity,
                        img_url: IMAGE_URL + '?day=' + msg.request_day + '&id=' + msg.request_id,
                        frame_url: FRAME_URL + '?&id=' + msg.request_id
                    }
                }
            });
            setServes(serves_temp);
        } else if (msg.type === "ERROR") {
            let serve = {
                id: 'err',
                info: msg.info
            }
            let serves_temp = [...serves, serve]
            serves_temp = serves_temp.slice(-3)
            setServes(serves_temp);
        }
    };

    const elementFromServe = (serve) => {
        if (serve.velocity) {
            return <div className="ServePanel" key={serve.id}>
                        <span className='Timestamp'>{serve.timestamp}</span>
                        <br/>
                        <b>{serve.velocity} km/h</b>
                        <br/>
                        <img className="ServeImage" alt="" src={serve.img_url}></img>
                        <br/>
                        <img className="ServeImage" alt="" src={serve.frame_url}></img>
                    </div>
        } else if (serve.id !== 'err') {
            return <div className="ServePanel" key={serve.id}>
                        <span className='Timestamp'>{serve.info}</span>
                    </div>
        } else {
            return <div className="ServePanel" key={serve.id}>
                        <span className='ErrorSpan'>{serve.timestamp}</span>
                    </div>
        }
    }

    return (
        <div className="App">
            <h1 className="Title">Serve Velocities</h1>
                <div className="StatusDiv">
                    Socket status: {socketStatus}
                </div>
                <div className="row">
                    {serves.map( m => elementFromServe(m))}
                </div>
        </div>
    )

};

export default ServePanel;